import React, { useState, useCallback } from 'react';
import { H1, P, Input, Textfield, Button, Select } from '../';
import { inqueryFormTypes, HONEYPOT_FIELD, ContactFormStates } from '../../constants';
import { useHasUnmounted } from '../../hooks';

interface ContactFormProps {
  className?: string;
  withH1?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ className = '', withH1 = false }) => {
  const hasUnmounted = useHasUnmounted();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState(inqueryFormTypes[0]);
  const [honeypot, setHoneypot] = useState('');

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (honeypot) {
        console.log('spam detected');
        return false;
      }

      if (!email || !name) {
        console.log('values missing');
        return false;
      }

      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          message,
          type,
          commentHP: honeypot,
        }),
      });

      const { state } = await response.json();
      if (state === ContactFormStates.success) {
        setMessage('');
        setName('');
        setEmail('');
        setType(inqueryFormTypes[0]);
        setError(false);
        setSubmitted(true);
      } else {
        setError(true);
        setSubmitted(false);
      }

      setTimeout(() => {
        if (!hasUnmounted.current) {
          setError(false);
          setSubmitted(false);
        }
      }, 8000);
    },
    [email, name, message, type, honeypot],
  );

  return (
    <div className={`flex flex-col justify-center items-center text-center mobile:px-5 ${className}`}>
      <H1 asH2={!withH1}>Contact Us</H1>
      <P>Place an order using the form below and we will contact you.</P>
      <form onSubmit={onSubmit} className="flex flex-col justify-center items-center gap-5 my-5 w-full m-auto">
        <input
          className="sr-only"
          type="text"
          name={HONEYPOT_FIELD}
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
        />
        <Input id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} label="Name" required />
        <Input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          required
        />
        <Textfield
          id="message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Message"
        />
        <Select
          id="type"
          label="What are you looking for?"
          selected={type}
          setSelected={setType}
          items={inqueryFormTypes}
          required
        />
        <Button type="submit" primary>
          Send it
        </Button>
        {submitted && (
          <div className="flex m-auto">
            <p className="m-auto text-center mb-5 py-2 px-5 bg-status-green">
              Thank you for reaching out to us! We will come back to you swiftly.
            </p>
          </div>
        )}
        {error && (
          <div className="flex m-auto">
            <p className="m-auto text-center mb-5 py-2 px-5 bg-status-red">
              We are very sorry but something went wrong. Please try later again.
            </p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
