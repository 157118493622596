import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import VimeoPlayer from 'react-vimeo-progress';
import Layout from '../components/layout/layout';
import ContactForm from '../components/forms/contact';
import { H1, H2, P } from '../components';

const items = [
  {
    title: 'Look',
    description: 'You drink with your eyes first. Take your time to admire an elegant beer glass.',
    imageUrl: '/images/glassware/look.png',
    position: { top: '20%', left: '10%' },
  },
  {
    title: 'Touch',
    description: 'Feel the smooth transitions and a durable sterm.',
    imageUrl: '/images/glassware/touch.png',
    position: { bottom: '20%', left: '10%' },
  },
  {
    title: 'Pour',
    description: 'Pour the beer down the glass with vigor.',
    imageUrl: '/images/glassware/pour.png',
    position: { right: '10%', top: '20%' },
  },
  {
    title: 'Smell',
    description: 'Rotate slowly to release all the aromas. Smell them and soak the complex scents.',
    imageUrl: '/images/glassware/smell.png',
    position: { left: '10%', top: '20%' },
  },
  {
    title: 'Taste',
    description: 'Let every sense taste celebrate the taste. Experience history in a glass.',
    imageUrl: '/images/glassware/taste.png',
    position: { left: '10%', bottom: '20%' },
  },
];

export default function Glassware() {
  return (
    <Layout title="Glassware">
      <div className="w-full pt-32 md:pt-40 lg:pt-60">
        <div className="px-10 pb-10 lg:pb-20 flex flex-col justify-center items-center text-center">
          <H1>LOKA BELGIAN BEER GLASS</H1>
          <P big>
            Your favourite beer will taste better in a LOKA Belgian Beer glass. The special shape carved out of crystal
            delivers the complexity of aromas on the nose while demonstrating the optimum beer texture, balance and
            flavor intensity on the palate
          </P>
          <div className="w-full">
            <StaticImage src="../assets/images/experience/glassware.jpg" alt="Three Lokabrews beer glasses" />
          </div>
        </div>
        <div className="min-h-screen w-full bg-secondary overflow-y-scroll">
          {items.map((item) => (
            <div
              key={item.title}
              className="relative w-full bg-cover bg-fixed bg-center bg-no-repeat"
              style={{ height: '100vh', backgroundImage: `url("${item.imageUrl}")`, backgroundSize: 'auto 50vh' }}
            >
              <div
                className="absolute max-w-xl p-3 lg:p-20 bg-white border-4 border-primary my-20 lg:my-0"
                style={item.position}
              >
                <H2 className="override:max-w-lg">{item.title}</H2>
                <P className="override:max-w-lg">{item.description}</P>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full px-10 my-20 lg:mt-40 flex flex-col lg:flex-row justify-center items-center gap-10">
          <VimeoPlayer
            id="glass_crafts"
            link="https://vimeo.com/672941669"
            widthPercentage={0.3}
            smWidthPercentage={0.9}
            mdWidthPercentage={0.9}
            lgWidthPercentage={0.3}
            xlWidthPercentage={0.3}
            maxWidth={1200}
            vimeoOptions={{ autoplay: true, loop: true, muted: true }}
          />
          <div>
            <H2>HANDCRAFTED BELGIAN CRYSTAL GLASS</H2>
            <P>
              Staying faithful to the spirit of Belgian glass making craftsmanship, each of our glasses is handcrafted
              which results in a unique, functional glass to bring out the best in our beers.
            </P>
          </div>
        </div>
        <ContactForm className="min-h-screen w-full text-center flex flex-col gap-10 justify-center items-center py-10" />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
